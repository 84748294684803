import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject} from "rxjs";
import {UserRoleEnum} from "../enums/userRole.enum";
import {FullUserDto} from "../../rest/user/dto/fullUser.dto";

@Injectable()
export class AuthService {
  private token: string | null = null;
  private id: number | undefined;

  private role: UserRoleEnum | undefined = undefined;
  private manager: FullUserDto | undefined = undefined;
  private user: FullUserDto | undefined = undefined;

  private settingsHighlightStatus: boolean = false;
  private settingsHighlightStatusLead: boolean = false;

  managerData: BehaviorSubject<FullUserDto | undefined> = new BehaviorSubject(this.manager);
  userData: BehaviorSubject<FullUserDto | undefined> = new BehaviorSubject(this.user);
  userRole: BehaviorSubject<UserRoleEnum | undefined> = new BehaviorSubject(this.role);

  addEventTrigger: BehaviorSubject<boolean> = new BehaviorSubject(false);
  addUserDataTrigger: BehaviorSubject<boolean> = new BehaviorSubject(false);

  settingsUpdate: BehaviorSubject<string> = new BehaviorSubject('false');

  constructor(
    private router: Router
  ) {
  }

  setToken(token: string): void {
    this.token = token;
    localStorage.setItem('token', token);
  }

  setRole(role: UserRoleEnum): void {
    this.role = role;
    this.userRole.next(this.role);

    localStorage.setItem('role', role);
  }

  setManager(manager: FullUserDto): void {
    this.manager = manager;
    this.managerData.next(this.manager);
  }

  setUserData(user: FullUserDto): void {
    this.user = user;
    this.userData.next(this.user);
  }

  eventTrigger(status: boolean): void {
    this.addEventTrigger.next(status);
  }

  userDataTrigger(status: boolean): void {
    this.addUserDataTrigger.next(status);
  }

  getToken(): string | null {
    if (this.token) {
      return this.token;
    }
    return localStorage.getItem('token');
  }

  getRole(): UserRoleEnum | undefined {
    if (this.role) {
      return this.role;
    }

    return localStorage.getItem('role') ? localStorage.getItem('role') as UserRoleEnum : undefined;
  }

  logout(): void {
    this.token = null;
    this.role = undefined;

    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  isAuthorized(): boolean {
    return !!this.getToken();
  }

  isCustomer(): boolean {
    const role = this.getRole();

    if (!role) {
      this.logout();
    }

    return role === UserRoleEnum.CUSTOMER;
  }

  setId(id: number) {
    this.id = id;
    localStorage.setItem('id', String(id));
  }

  getId(): number | undefined {
    if (this.id) {
      return this.id;
    }

    return localStorage.getItem('id') ? Number(localStorage.getItem('id')) : undefined;
  }

  setSettingsHighlightStatus(status: boolean): void {
    this.settingsHighlightStatus = status;
    localStorage.setItem('settingsHighlightStatus', String(this.settingsHighlightStatus));
  }

  getHighlightStatus(): boolean {
    if (this.settingsHighlightStatus) {
      return this.settingsHighlightStatus;
    }

    if (localStorage.getItem('settingsHighlightStatus')) {
      return JSON.parse(localStorage.getItem('settingsHighlightStatus') || '{}');
    }

    return false;
  }

  setSettingsHighlightStatusLead(status: boolean): void {
    this.settingsHighlightStatusLead = status;
    localStorage.setItem('settingsHighlightStatusLead', String(this.settingsHighlightStatusLead));
  }

  getHighlightStatusLead(): boolean {
    if (this.settingsHighlightStatusLead) {
      return this.settingsHighlightStatusLead;
    }

    if (localStorage.getItem('settingsHighlightStatusLead')) {
      return JSON.parse(localStorage.getItem('settingsHighlightStatusLead') || '{}');
    }

    return false;
  }
}
