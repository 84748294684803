import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {Observable} from "rxjs";
import {CreateCommentDto} from "./dto/createComment.dto";

@Injectable({
  providedIn: 'root'
})
export class CommentsRestService {
  constructor(private http: HttpClient) {
  }

  getUserComments(options: {
    userId?: number,
    pagination?: PaginationDto,
    search?: string,
  }): Observable<any> {
    const query: any = {};

    query['filters'] = {};

    if (options.pagination) {
      query['pagination'] = options.pagination;
    }

    query['sort'] = ['pinned:DESC', 'id:DESC'];
    query['populate'] = ['author', 'files'];

    if (options.userId) {
      query['filters'].user = {
        id: {
          $eq: options.userId,
        },
      };
    }

    if (options.search) {
      query['filters'].text = {
        $contains: options.search,
      };
    }

    return this.http.get(`/comments?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getLeadComments(options: {
    leadId?: number,
    pagination?: PaginationDto,
    search?: string,
  }): Observable<any> {
    const query: any = {};

    query['filters'] = {};

    if (options.pagination) {
      query['pagination'] = options.pagination;
    }

    query['sort'] = ['pinned:DESC', 'id:DESC'];
    query['populate'] = ['author', 'files'];

    if (options.leadId) {
      query['filters'].lead = {
        id: {
          $eq: options.leadId,
        },
      };
    }

    if (options.search) {
      query['filters'].text = {
        $contains: options.search,
      };
    }

    return this.http.get(`/lead-comments?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  updateCommentUserByID(id: number, data: any) {
    return this.http.put(`/comments/${id}`, {data: data});
  }

  updateCommentLeadByID(id: number, data: any) {
    return this.http.put(`/lead-comments/${id}`, {data: data});
  }

  createCommentByUser(comment: any): Observable<any> {
    return this.http.post('/comments', {data: comment});
  }

  createCommentByLead(comment: any): Observable<any> {
    return this.http.post('/lead-comments', {data: comment});
  }

  deleteCommentByUserID(id: number) {
    return this.http.delete(`/comments/${id}`);
  }

  deleteCommentByLeadID(id: number) {
    return this.http.delete(`/lead-comments/${id}`);
  }
}
