import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './auth.service';
import {environment} from '../../../environments/environment';
import {MessagesService} from '../services/message-service/messages.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable()
export class RestInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private messagesService: MessagesService,
  ) {
  }

  // tslint:disable-next-line:typedef
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.authService.isAuthorized()) {
      request = request.clone({
        url: !request.url.includes('/testCore') ? environment.backendUrl + request.url : request.url,
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`
        }
      });
    } else {
      request = request.clone({
        url: !request.url.includes('/testCore') ? environment.backendUrl + request.url : request.url,
      });
    }

    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err.status === 401) {
          this.authService.logout();
          this.messagesService.createMessage(
            'error',
            'The token has expired, please log back into the system.'
          );
        } else if (err.status === 403) {
          this.messagesService.createMessage(
            'error',
            '403 Forbidden — доступ запрещен.'
          );
        } else if (err.status === 500) {
          this.messagesService.createMessage(
            'error',
            'There was an error sending your request, please try again later.',
          );
        } else {
          this.messagesService.createMessage(
            'error',
            err.error.message,
          );
        }
        return throwError(err);
      })
    );
  }
}
