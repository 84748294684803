import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {LayoutComponent} from "./layout.component";
import {AuthDashboardGuard} from "../common/guards/auth-dashboard.guard";
import {RoleGuard} from "../common/guards/role.guard";
import {UserRoleEnum} from "../common/enums/userRole.enum";
import {NotCustomerGuard} from "../common/guards/notcustomer.guard";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthDashboardGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'leads',
      },
      {
        path: 'statistics',
        loadChildren: () => import('../pages/statistics/statistics.module').then(m => m.StatisticsModule),
      },
      {
        path: 'statistics-integration',
        loadChildren: () => import('../pages/statistics-integration/statistics-integration.module').then(m => m.StatisticsIntegrationModule),
      },
      {
        path: 'leads',
        loadChildren: () => import('../pages/leads/leads.module').then(m => m.LeadsModule),
        canActivate: [NotCustomerGuard],
      },
      {
        path: 'companies',
        loadChildren: () => import('../pages/companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN,]}
      },
      {
        path: 'import-csv',
        loadChildren: () => import('../pages/csv/csv.module').then(m => m.CsvModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'managers',
        loadChildren: () => import('../pages/managers/managers.module').then(m => m.ManagersModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'databases',
        loadChildren: () => import('../pages/databases/databases.module').then(m => m.DatabasesModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'integrations',
        loadChildren: () => import('../pages/integrations/integrations.module').then(m => m.IntegrationsModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN,]}
      },
      {
        path: 'history',
        loadChildren: () => import('../pages/history/history.module').then(m => m.HistoryModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class LayoutRoutesModule {
}
