import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {
  constructor() {
  }

  setString(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  getString(key: string): string | null {
    const value = localStorage.getItem(key);
    return value ? value : null;
  }

  setBoolean(key: string, value: boolean): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getBoolean(key: string): boolean | null {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }

  private isDateString(value: any): boolean {
    return !isNaN(Date.parse(value));
  }

  private isArrayOfDates(value: any[]): boolean {
    return value.every(item => this.isDateString(item));
  }
}
