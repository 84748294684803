import {Component, Input, OnInit} from '@angular/core';
import {FullUserDto} from "../../../rest/user/dto/fullUser.dto";
import {UserRoleEnum} from "../../../common/enums/userRole.enum";
import {NzModalRef} from "ng-zorro-antd/modal";
import {AuthService} from "../../../common/auth/auth.service";
import {MessagesService} from "../../../common/services/message-service/messages.service";
import {FiltersRestService} from "../../../rest/filters/filters-rest.service";
import {UserRestService} from "../../../rest/user/user-rest.service";
import {FullManagerDto} from "../../../rest/auth/dto/full-manager.dto";
import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";
import {PaginationDto} from "../../../common/pagination/pagination.dto";
import {CompaniesService} from "../../../rest/companies/companies.service";
import {countries} from "../../../common/country/countries";
import {leadStatusList} from "../../../pages/leads/lead-status.enum";
import {ManagersService, User} from "../../../common/services/managers/managers.service";
import {dateRangegWithEnum, formattedDateRanges} from "../../../common/dates/date-ranges";
import {DatabaseFullDto} from "../../../rest/databases/dto/DatabaseFull.dto";
import {DatabasesRestService} from "../../../rest/databases/databases-rest.service";

@Component({
  selector: 'app-create-filter',
  templateUrl: './create-filter.component.html',
  styleUrls: ['./create-filter.component.scss']
})
export class CreateFilterComponent implements OnInit {
  @Input() type: 'LEAD' = 'LEAD';

  // me
  manager: FullUserDto | undefined;
  role: UserRoleEnum | undefined;

  // companies
  companies: any[] = [];
  companiesForFilter: NzTableFilterList = [];

  // databases
  databases: any[] = [];
  databasesForFilter: NzTableFilterList = [];

  data: any = {
    companies: [],
    databases: [],
    status: [],
    name: undefined,
    country: undefined,

    createdAtDate: undefined,
    firstDepositeTime: undefined,

    hideNotActiveCompanies: true,
  }

  // country
  countryList = countries;

  // helpers
  helpers = {
    loadCompanies: false,
    loadDatabases: false,
  }

  // enums
  UserRoleEnum = UserRoleEnum;

  // status
  leadStatus = leadStatusList;
  statusForFilter: NzTableFilterList = [];

  // ranges
  ranges = dateRangegWithEnum;

  constructor(
    private modal: NzModalRef,
    private authService: AuthService,
    private messageService: MessagesService,
    private userRestService: UserRestService,
    private companiesService: CompaniesService,
    private managersService: ManagersService,
    private databasesRestService: DatabasesRestService,
    private filterResetService: FiltersRestService,
  ) {
  }

  ngOnInit(): void {
    this.getMe();

    if (this.type === 'LEAD') {
      this.statusForFilter = this.leadStatus;
    }
  }

  getMe(): void {
    this.authService.managerData.subscribe(
      user => {
        if (user) {
          this.manager = new FullUserDto(user);
        }
      }
    );

    this.authService.userRole.subscribe(
      role => {
        if (role) {
          this.role = role;
          this.getCompanies();
          this.getDatabases();
        }
      }
    )
  }

  // get companies
  getCompanies(): void {
    this.companiesService.getCompanies(new PaginationDto({pageSize: 100}))
      .subscribe(response => {
        response.data.forEach((company: any, index: number) => {
          this.addCompanyToFilterList(company);
        });

        this.helpers.loadCompanies = true;
      });
  }

  // get databases
  getDatabases(): void {
    this.databasesRestService.getDatabases(new PaginationDto({pageSize: 150})).subscribe(
      response => {
        this.databases = response.data;

        this.databasesForFilter = this.databases.map((database: DatabaseFullDto) => {
          return {
            text: database.name,
            value: String(database.id),
          }
        });

        this.helpers.loadDatabases = true;
      }
    )
  }

  addCompanyToFilterList(company: any) {
    this.companies.push({
      isActive: company.isActive,
      text: company.name,
      value: company.id,
    });

    this.filterCompaniesList();
  }

  filterCompaniesList(): void {
    this.companiesForFilter = this.data.hideNotActiveCompanies ?
      this.companies.filter((company: any) => company.isActive) : this.companies;
  }

  // event
  changeSelectEvent(event: any, type: 'companies' | 'databases' | 'country' | 'status') {
    if (type === 'companies') {
      this.data.companies = event;
    }

    if (type === 'country') {
      this.data.countries = event;
    }

    if (type === 'databases') {
      this.data.databases = event;
    }

    if (type === 'status') {
      this.data.status = event;
    }
  }

  // change companies status
  changeActiveCompaniesList(event: any): void {
    if (typeof event == "boolean") {

      this.data.companies = [];
      this.data.hideNotActiveCompanies = event;

      this.filterCompaniesList();
    }
  }

  // create
  createFilter(): void {
    if (this.data.name && this.manager?.id) {
      const filter = {
        user: this.manager.id,
        data: {
          name: this.data.name,
          type: this.type,
          filters: {
            createdAtDate: this.data.createdAtDate,
            firstDepositeTime: this.data.firstDepositeTime,
            status: this.data.status,
            companies: this.data.companies,
            databases: this.data.databases,
            country: this.data.countries,
          }
        }
      };

      this.filterResetService.createFilter(filter).subscribe(
        _ => {
          this.modal.close(true);
        }
      )
    }
  }

  // date changes
  changeCreatedAtDate(createdAtDates: any): void {
    this.data.createdAtDate = createdAtDates;
  }

  changeFirstDepositDate(lastCommentDates: any): void {
    this.data.firstDepositeTime = lastCommentDates;
  }
}
