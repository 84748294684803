import { Component, OnInit } from '@angular/core';
import {PaginationDto} from "../../../common/pagination/pagination.dto";
import {FullUserDto} from "../../../rest/user/dto/fullUser.dto";
import {NzModalRef} from "ng-zorro-antd/modal";
import {MessagesService} from "../../../common/services/message-service/messages.service";
import {UserRestService} from "../../../rest/user/user-rest.service";
import {DatabasesRestService} from "../../../rest/databases/databases-rest.service";
import {DatabaseCreateDto} from "../../../rest/databases/dto/DatabaseCreate.dto";

@Component({
  selector: 'app-creation',
  templateUrl: './creation-database.component.html',
  styleUrls: ['./creation-database.component.scss']
})
export class CreationDatabaseComponent implements OnInit {
  name?: string;
  description?: string;

  managers: number[] = [];
  managersList: FullUserDto[] = [];

  pagination = new PaginationDto({pageSize: 999});

  filters = {
    loadManagers: false,
  }

  constructor(
    private modal: NzModalRef,
    private databasesRestService: DatabasesRestService,
    private messageService: MessagesService,
    private userRestService: UserRestService,
  ) { }

  ngOnInit() {
    this.getManagers();
  }

  getManagers(): void {
    const data = {
      pagination: this.pagination,
    };

    this.userRestService.getManagers(data).subscribe(
      response => {
        this.managersList = response.data;
        this.filters.loadManagers = true;
      }
    )
  }

  createDatabase() {
    if (!this.name) {
      return;
    }

    const data = new DatabaseCreateDto({
      name: this.name,
      description: this.description,
      managers: this.managers,
    });

    this.databasesRestService.createDatabase(data)
      .subscribe(
        response => {
          this.modal.close();
          this.messageService.createMessage(
            'success',
            'База успешно создана.'
          );
        },
        _ => {
          this.messageService.createMessage('error', 'Ошибка при создании');
        });
  }
}
