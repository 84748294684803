import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {NzTypographyModule} from 'ng-zorro-antd/typography';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzDescriptionsModule} from 'ng-zorro-antd/descriptions';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzMessageModule} from 'ng-zorro-antd/message';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzCardModule} from 'ng-zorro-antd/card';

import {IconDefinition} from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import {NZ_ICONS, NzIconModule} from 'ng-zorro-antd/icon';
import {ConfirmEqualValidatorDirective} from "../../validator/confirm-equal-validator.directive";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzTagModule} from "ng-zorro-antd/tag";
import { NzImageModule } from 'ng-zorro-antd/image';
import {NzPaginationModule} from "ng-zorro-antd/pagination";
import {NzEmptyModule} from "ng-zorro-antd/empty";
import {NzUploadModule} from "ng-zorro-antd/upload";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzStatisticModule} from "ng-zorro-antd/statistic";
import {NzListModule} from "ng-zorro-antd/list";
import {NzCommentModule} from "ng-zorro-antd/comment";
import {NzPopoverModule} from "ng-zorro-antd/popover";
import {NzNotificationModule} from "ng-zorro-antd/notification";
import {NzTimePickerModule} from "ng-zorro-antd/time-picker";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {NzSegmentedModule} from "ng-zorro-antd/segmented";

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};

const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => {
  return antDesignIcons[key];
});

@NgModule({
  imports: [
    CommonModule,
    NzLayoutModule,
    NzIconModule,
    NzMenuModule,
    NzTypographyModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzDescriptionsModule,
    NzStepsModule,
    NzInputModule,
    NzDrawerModule,
    NzPaginationModule,
    NzMessageModule,
    NzModalModule,
    NzCardModule,
    NzFormModule,
    NzTabsModule,
    NzDividerModule,
    NzTableModule,
    NzListModule,
    NzSwitchModule,
    NzSelectModule,
    NzSkeletonModule,
    NzToolTipModule,
    NzDatePickerModule,
    NzDropDownModule,
    NzEmptyModule,
    NzUploadModule,
    NzBadgeModule,
    NzAvatarModule,
    NzTagModule,
    NzImageModule,
    NzRadioModule,
    NzNotificationModule,
    NzStatisticModule,
    NzCommentModule,
    NzCheckboxModule,
    NzPopoverModule,
    NzProgressModule,
    NzTimePickerModule,
    NzSegmentedModule,
  ],
  exports: [
    NzLayoutModule,
    NzIconModule,
    NzMenuModule,
    NzTypographyModule,
    NzPageHeaderModule,
    NzButtonModule,
    NzDescriptionsModule,
    NzStepsModule,
    NzInputModule,
    NzMessageModule,
    NzModalModule,
    NzFormModule,
    NzTabsModule,
    NzDrawerModule,
    NzListModule,
    NzCommentModule,
    NzDividerModule,
    NzTableModule,
    NzCardModule,
    NzSwitchModule,
    NzSelectModule,
    NzSkeletonModule,
    NzToolTipModule,
    NzDatePickerModule,
    NzPaginationModule,
    NzEmptyModule,
    NzUploadModule,
    ConfirmEqualValidatorDirective,
    NzDropDownModule,
    NzBadgeModule,
    NzAvatarModule,
    NzTagModule,
    NzImageModule,
    NzStatisticModule,
    NzRadioModule,
    NzNotificationModule,
    NzTimePickerModule,
    NzCheckboxModule,
    NzPopoverModule,
    NzProgressModule,
    NzSegmentedModule,
  ],
  declarations: [
    ConfirmEqualValidatorDirective
  ],
  providers: [
    {
      provide: NZ_ICONS,
      useValue: icons
    }
  ]
})
export class NgZorroModule {
}
