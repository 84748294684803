import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from "./app.routing.module";
import {LayoutModule} from "./layouts/layout.module";
import {NzFormModule} from "ng-zorro-antd/form";
import {AuthRestModule} from "./rest/auth/auth-rest.module";
import {AuthModule} from "./common/auth/auth.module";
import {RestInterceptor} from './common/auth/auth.interseptor';
import {UserRestModule} from "./rest/user/user-rest.module";
import {CustomFormsModule} from "ng2-validation";
import {NgZorroModule} from "./common/services/ng-zorro/ng-zorro.module";
import {CommentsRestModule} from "./rest/comments/comments-rest.module";

import {NZ_I18N, ru_RU} from 'ng-zorro-antd/i18n';
import ru from '@angular/common/locales/ru';
import {SettingsModule} from "./common/services/settings/settings.module";
import {ComponentModule} from "./component/component.module";
import {FiltersRestModule} from "./rest/filters/filters-rest.module";
import {DatabasesModule} from "./pages/databases/databases.module";

registerLocaleData(ru);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AuthModule,
    LayoutModule,
    NzFormModule,
    BrowserModule,
    NgZorroModule,
    AuthRestModule,
    UserRestModule,
    HttpClientModule,
    AppRoutingModule,
    CustomFormsModule,
    CommentsRestModule,
    SettingsModule,
    ComponentModule,
    FiltersRestModule,
    DatabasesModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {provide: NZ_I18N, useValue: ru_RU},
    {provide: HTTP_INTERCEPTORS, useClass: RestInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
