import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './layout.component';
import {RouterModule} from '@angular/router';
import {NgZorroModule} from '../common/services/ng-zorro/ng-zorro.module';
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {LayoutRoutesModule} from "./layout.routes.module";

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    NgZorroModule,
    NzDrawerModule,
    RouterModule,
    LayoutRoutesModule,
  ],
  exports: [
    LayoutComponent,
  ],
})
export class LayoutModule {}

