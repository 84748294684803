import {Component, Input, OnInit} from '@angular/core';
import {DatabaseFullDto} from "../../../rest/databases/dto/DatabaseFull.dto";
import {FullUserDto} from "../../../rest/user/dto/fullUser.dto";
import {PaginationDto} from "../../../common/pagination/pagination.dto";
import {NzModalRef} from "ng-zorro-antd/modal";
import {DatabasesRestService} from "../../../rest/databases/databases-rest.service";
import {MessagesService} from "../../../common/services/message-service/messages.service";
import {UserRestService} from "../../../rest/user/user-rest.service";

@Component({
  selector: 'app-update',
  templateUrl: './update-database.component.html',
  styleUrls: ['./update-database.component.scss']
})
export class UpdateDatabaseComponent implements OnInit {
  @Input() databaseId?: number;

  database?: DatabaseFullDto;

  managers: number[] = [];
  managersList: FullUserDto[] = [];

  pagination = new PaginationDto({pageSize: 999});

  filters = {
    loadManagers: false,
  }

  constructor(
    private modal: NzModalRef,
    private databasesRestService: DatabasesRestService,
    private messageService: MessagesService,
    private userRestService: UserRestService,
  ) {}

  ngOnInit() {
    this.getManagers();
    this.getDatabase();
  }

  getDatabase(): void {
    if(!this.databaseId) {
      this.modal.close();
    } else  {
      this.databasesRestService.getDatabase(this.databaseId).subscribe(
        response => {
          this.database = response;

          if (this.database && this.database.managers) {
            this.managers = this.database.managers ?
              this.database.managers?.map((manager: FullUserDto) => manager.id) : [];
          }
        }
      )
    }
  }

  getManagers(): void {
    const data = {
      pagination: this.pagination,
    };

    this.userRestService.getManagers(data).subscribe(
      response => {
        this.managersList = response.data;
        this.filters.loadManagers = true;
      }
    )
  }

  updateDatabase() {
    if (!this.database) {
      return;
    }

    const data = {
      name: this.database.name,
      description: this.database.description,
      managers: this.managers,
    };

    this.databasesRestService.updateDatabase(this.database.id, data).subscribe(
      response => {

        this.modal.close();
        this.messageService.createMessage(
          'success',
          'База успешно обновлена.'
        );
      },
      _ => {
        this.messageService.createMessage('error', 'Ошибка при обновлении');
      });
  }
}
