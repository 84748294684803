import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {CompaniesCreateDto} from "./dto/CompaniesCreate.dto";

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) {
  }

  getCompany(id: number): Observable<any> {
    const query: any = {};

    query['populate'] = {
      integrations: true,
    };

    return this.http.get(`/companies/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  getCompanies(pagination?: PaginationDto, search?: string, isActive?: boolean, populate = true): Observable<any> {
    const query: any = {};

    query['filters'] = {};

    query['sort'] = 'id:DESC';

    if(populate) {
      query['populate'] = {
        integrations: true,
      };
    }

    if (pagination) {
      query['pagination'] = pagination;
    }

    if (search) {
      query['filters'].name = {
        $containsi: search,
      };
    }

    query['filters'].isActive = {
      $in: isActive
    }

    return this.http.get(`/companies?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  createCompany(company: CompaniesCreateDto) {
    return this.http.post('/companies', {data: company});
  }

  updateCompanyById(id: number, company: any) {
    return this.http.put(`/companies/${id}`, {data: company});
  }

  deleteCompany(id: number): Observable<any> {
    return this.http.delete(`/companies/${id}`);
  }

  getStatistics(options: {
    to: Date,
    from: Date,
    companies?: number[],
    databases?: number[],
    integrations?: number[],
    country?: string,
    source?: string,
  }): Observable<any> {
    return this.http.get(`/companies/statistics/get?${qs.stringify(options)}`);
  }
}
