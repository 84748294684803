import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgZorroModule} from "../common/services/ng-zorro/ng-zorro.module";
import {FormsModule} from "@angular/forms";
import { PresetFiltersComponent } from './preset-filters/preset-filters.component';
import { UpdateFilterComponent } from './preset-filters/update-filter/update-filter.component';
import { CreateFilterComponent } from './preset-filters/create-filter/create-filter.component';
import { SelectFilterComponent } from './preset-filters/select-filter/select-filter.component';
import { TimelineBlockComponent } from './timeline-block/timeline-block.component';
import {NzTimelineModule} from "ng-zorro-antd/timeline";

@NgModule({
  declarations: [
    PresetFiltersComponent,
    UpdateFilterComponent,
    CreateFilterComponent,
    SelectFilterComponent,
    TimelineBlockComponent
  ],
  imports: [
    CommonModule,
    NgZorroModule,
    FormsModule,
    NzTimelineModule,
  ],
  exports: [
    PresetFiltersComponent,
    TimelineBlockComponent,
  ]
})
export class ComponentModule {
}
