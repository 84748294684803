import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
// @ts-ignore
import * as qs from "qs";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {map} from "rxjs/operators";
import {ChangeEmailDto} from "./dto/changeEmail.dto";
import {UpdatePasswordByAdminDto} from "./dto/updatePasswordByAdmin.dto";
import {CreateManagerDto} from "../auth/dto/create-manager.dto";
import {FullManagerDto} from "../auth/dto/full-manager.dto";

@Injectable()
export class UserRestService {

  constructor(private http: HttpClient) {
  }


  createManager(manager: CreateManagerDto): Observable<any> {
    return this.http.post('/auth/local/register', manager);
  }

  getManagers(options?: { pagination?: PaginationDto, }) {
    const query: any = {};

    if (options?.pagination) {
      query.pagination = options.pagination;
    }

    query['populate'] = ['role'];

    return this.http.get<{ data: FullManagerDto[] }>(`/users/managers?${qs.stringify(query)}`).pipe(
      map(response => {
        response.data = response.data
          .map(manager => new FullManagerDto(manager))
          .sort((a: FullManagerDto, b: FullManagerDto) => a.id - b.id);
        return response;
      })
    )
  }

  changeEmail(data: ChangeEmailDto) {
    return this.http.post(`/users/change-email`, data);
  }

  updatePasswordByAdmin(data: UpdatePasswordByAdminDto): Observable<any> {
    return this.http.post(`/users/change-password`, data);
  }

  getRole(id: number): Observable<any> {
    const query: any = {};

    query['populate'] = ['role'];

    return this.http.get(`/users/${id}?${qs.stringify(query)}`);
  }

  getMe(): Observable<any> {
    return this.http.get(`/users/me`);
  }

  updateUserById(id: number, user: any): Observable<any> {
    return this.http.put(`/users/${id}`, user);
  }

  deleteUser(userId: number) {
    return this.http.delete(`/users/${userId}`);
  }

  setLastOnline(id: number) {
    return this.http.put('/custom-user/was-online', {id})
  }

}
