import { NgModule } from '@angular/core';
import {AuthService} from './auth.service';
import {AuthLoginGuard} from '../guards/auth-login.guard';
import {AuthDashboardGuard} from '../guards/auth-dashboard.guard';
import {CustomerGuard} from "../guards/customer.guard";
import {NotCustomerGuard} from "../guards/notcustomer.guard";

@NgModule({
  providers: [
    AuthService,
    AuthLoginGuard,
    CustomerGuard,
    NotCustomerGuard,
    AuthDashboardGuard,
  ]
})
export class AuthModule { }
