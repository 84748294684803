<div
  class="page-header has-tab m-b-20">

  <div class="container">
    <div class="d-flex justify-content-between align-items-center">

      <h2 class="m-b-0 font-weight-semibold">
        Базы
      </h2>

      <button
        nz-button
        nzType="primary"
        (click)="createDatabase()">
        Создать
      </button>

    </div>
  </div>

</div>

<section>
  <div class="container">

    <nz-card>

      <div class="row align-items-md-center justify-content-end m-b-20">

        <div class="col-md-6 col-lg-8">

        </div>

        <div class="col-md-6 col-lg-4">
          <div class="d-flex align-items-start justify-content-end m-v-10">

            <nz-input-group nzSearch [nzAddOnAfter]="searchButton">

              <input
                type="text"
                nz-input
                name="search"
                (ngModelChange)="searchFilter($event)"
                [(ngModel)]="filter.search"
                placeholder="Поиск по базам"/>

            </nz-input-group>

            <ng-template #searchButton>
              <button
                nz-button
                nzType="primary"
                nzSearch
                (click)="clearSearch()"
                class="d-flex justify-content-center align-items-center">
                <i
                  nz-icon
                  nzType="clear"
                  style="width: 16px">
                </i>
              </button>
            </ng-template>

          </div>
        </div>

      </div>

      <div class="scroll-table__table">

        <nz-table
          #filterTable
          nzShowSizeChanger
          [nzData]="databases"
          [nzFrontPagination]="false"
          [nzTotal]="paginationOptions.total"
          [nzPageSize]="paginationOptions.pageSize"
          [nzPageIndex]="paginationOptions.page"
          (nzQueryParams)="onQueryParamsChange($event)"
          [nzLoading]="isLoading">
          <thead>
          <tr>

            <th [nzWidth]="'100px'">
              ID
            </th>

            <th [nzWidth]="'260px'">
              Название базы
            </th>

            <th [nzWidth]="'330px'">
              Описание
            </th>

            <th [nzWidth]="'450px'">
              Менеджеры
            </th>

            <th nzWidth="60px"></th>

          </tr>
          </thead>
          <tbody>
          <tr
            *ngFor="let database of filterTable.data"
            style="vertical-align: top;">

            <td>
              {{database.id}}
            </td>

            <td>
              {{database.name}}
            </td>

            <td>
              <ng-container class="text-wrap">
                {{database.description ? database.description : '-'}}
              </ng-container>
            </td>

            <td>

              <ng-container *ngIf="database.managers && database.managers && !database.managers.length">
                <nz-tag>
                  Без менеджеров
                </nz-tag>
              </ng-container>

              <ng-container
                *ngIf="database.managers && database.managers && database.managers.length">
                <ol class="row">
                  <li
                    *ngFor="let manager of database.managers"
                    class="col-md-6 col-lg-4">
                    <nz-tag
                      [nzColor]="'cyan'">
                      {{manager.firstName + '' + manager.lastName}}
                    </nz-tag>
                  </li>
                </ol>
              </ng-container>
            </td>

            <td>
              <a
                nz-dropdown
                [nzDropdownMenu]="menu"
                class="d-inline hover-button">
                <i
                  nz-icon
                  nzType="appstore"
                  theme="outline">
                </i>
              </a>

              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>

                  <li
                    (click)="updateDatabase(database)"
                    nz-menu-item>

                    <i
                      nz-icon
                      nzType="edit"
                      class="m-r-5">
                    </i>

                    Редактировать базу

                  </li>

                  <li
                    *ngIf="(role && role === UserRoleEnum.ADMIN) && database"
                    (click)="showDeleteConfirm(database)"
                    nz-menu-item>

                    <i
                      nz-icon
                      nzType="delete"
                      class="m-r-5">
                    </i>

                    Удалить базу

                  </li>

                </ul>
              </nz-dropdown-menu>
            </td>

          </tr>
          </tbody>
        </nz-table>

      </div>

      <div
        *ngIf="databases.length"
        class="font-size-13 scroll-table__pagination">
        <span class="text-muted">Всего баз:</span>
        {{paginationOptions.total ? paginationOptions.total : 0}}
      </div>

    </nz-card>

  </div>
</section>
