import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MessagesService} from '../common/services/message-service/messages.service';
import {LanguageService} from "../common/services/language/language.service";
import {FullUserDto} from "../rest/user/dto/fullUser.dto";
import {UserRestService} from "../rest/user/user-rest.service";
import {AuthService} from "../common/auth/auth.service";
import {UserRoleEnum} from "../common/enums/userRole.enum";
import {NzModalService} from "ng-zorro-antd/modal";
import {LeadStatusEnum} from "../pages/leads/lead-status.enum";

@Component({
  selector: 'app-layouts',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('template', {static: false}) template?: TemplateRef<{}>;

  user: FullUserDto | undefined;
  role: UserRoleEnum = UserRoleEnum.MANAGER;

  isFolded: boolean | undefined;
  isExpand: boolean | undefined;

  UserRoleEnum = UserRoleEnum;
  LeadStatusEnum = LeadStatusEnum;

  trigger: any;

  date = new Date();

  document = document;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private userRestService: UserRestService,
    private messageServices: MessagesService,
    private modal: NzModalService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.getUser();
    this.getSideMenuParams();
  }

  ngOnDestroy(): void {
    if (this.trigger) {
      clearInterval(this.trigger);
    }
  }

  toggleFold() {
    this.isFolded = !this.isFolded;

    this.setSideMenuParams();
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;

    this.setSideMenuParams();
  }

  setSideMenuParams(): void {
    // @ts-ignore
    localStorage.setItem('isExpand', this.isExpand ? this.isExpand : JSON.stringify(false));
    // @ts-ignore
    localStorage.setItem('isFolded', this.isFolded ? this.isFolded : JSON.stringify(false));
  }

  getSideMenuParams(): void {
    // @ts-ignore
    this.isExpand = localStorage.getItem('isExpand') ? JSON.parse(localStorage.getItem('isExpand')) : false;
    // @ts-ignore
    this.isFolded = localStorage.getItem('isFolded') ? JSON.parse(localStorage.getItem('isFolded')) : false;
  }

  getUser(): void {
    this.userRestService.getMe().subscribe(
      user => {
        this.user = new FullUserDto(user);

        this.authService.setId(this.user.id);
        this.authService.setManager(this.user);

        this.getRole();
      }
    )
  }

  getRole(): void {
    if (this.user && this.user.id) {
      this.userRestService.getRole(this.user.id).subscribe(
        response => {
          if (response.role.name) {
            this.role = response.role.name;
          }
          this.authService.setRole(this.role);

          if (this.role === 'Customer') {
            this.authService.logout();
            this.messageServices.createMessage(
              'error',
              'Произошла ошибка при попытке входа.'
            );
          }
        }
      )
    }
  }

  logOut(): void {
    this.authService.logout();
    this.router.navigateByUrl('/login');
    this.messageServices.createMessage(
      'success',
      'Вы успешно вышли из системы.',
    );
  }

  // open new tab
  openLinkFromTab(event: any, url: string) {
    if (event.button === 1) {
      window.open(`/${url}`, '_blank');
    }
  }
}
