import {Component, Input, OnInit} from '@angular/core';
import {FullUserDto} from "../../../rest/user/dto/fullUser.dto";
import {UserRoleEnum} from "../../../common/enums/userRole.enum";
import {AuthService} from "../../../common/auth/auth.service";
import {FilterDto} from "../../../rest/filters/dto/filter.dto";
import {PaginationDto} from "../../../common/pagination/pagination.dto";
import {FiltersRestService} from "../../../rest/filters/filters-rest.service";
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {
  @Input() type: 'LEAD' = 'LEAD';

  // me
  manager: FullUserDto | undefined;
  role: UserRoleEnum | undefined;

  // load list
  helpers = {
    loadList: false,
    initList: false,
  }

  // filters list
  filtersList: FilterDto[] = [];

  // pagination
  paginationOptions: PaginationDto = new PaginationDto({
    page: 1,
    pageCount: 1,
    pageSize: 50,
    total: 0,
  });

  constructor(
    private authService: AuthService,
    private modal: NzModalRef,
    private filterResetService: FiltersRestService,
  ) { }

  ngOnInit(): void {
    this.getMe();
  }

  getMe(): void {
    this.authService.managerData.subscribe(
      user => {
        if (user) {
          this.manager = new FullUserDto(user);
        }
      }
    );

    this.authService.userRole.subscribe(
      role => {
        if (role) {
          this.role = role;
          this.getFilters();
        }
      }
    )
  }

  // get filters
  getFilters(): void {
    if (this.manager?.id) {
      this.helpers.loadList = true;
      this.filterResetService.getFilters({
        pagination: this.paginationOptions,
        user: this.manager.id,
        type: this.type,
      }).subscribe(
        filters => {
          this.filtersList = [];
          this.paginationOptions = filters.meta.pagination;

          if (!this.helpers.initList) this.helpers.initList = true;

          this.filtersList = filters.data.map((filter: FilterDto) => {
            return filter;
          });

          this.helpers.loadList = false;
        }
      )
    }
  }

  selectFilter(filter: FilterDto): void {
    if(filter) {
      this.modal.close(filter);
    }
  }
}
