import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatabasesComponent} from './databases.component';
import {NgZorroModule} from "../../common/services/ng-zorro/ng-zorro.module";
import {RouterModule, Routes} from "@angular/router";
import {NzPopoverModule} from "ng-zorro-antd/popover";
import {FormsModule} from "@angular/forms";
import { CreationDatabaseComponent } from './creation/creation-database.component';
import { UpdateDatabaseComponent } from './update/update-database.component';

const route: Routes = [
  {
    path: '',
    component: DatabasesComponent,
  }
];


@NgModule({
  declarations: [
    DatabasesComponent,
    CreationDatabaseComponent,
    UpdateDatabaseComponent
  ],
  imports: [
    CommonModule,
    NgZorroModule,
    RouterModule.forChild(route),
    NzPopoverModule,
    FormsModule,
  ]
})
export class DatabasesModule {
}
