<form
  nz-form
  #createDatabaseForm="ngForm"
  autocomplete="off"
  nzLayout="vertical"
  class="login-form"
  (ngSubmit)="createDatabaseForm.valid && createDatabase()">

  <nz-form-item class="relative">

    <nz-form-label
      nzRequired
      nzFor="name">
      Имя базы
    </nz-form-label>

    <nz-form-control>
      <nz-input-group>
        <input
          #databaseName="ngModel"
          type="text"
          class="form-control"
          id="name"
          name="name"
          nz-input
          required
          [(ngModel)]="name"
          placeholder="Введите имя">
      </nz-input-group>
    </nz-form-control>

    <div
      *ngIf="databaseName.invalid && (databaseName.dirty || databaseName.touched)"
      class="form_error">
      <span *ngIf="databaseName.errors?.['required']">
        Обязательное поле
      </span>
    </div>

  </nz-form-item>

  <nz-form-item>

    <nz-form-label
      nzFor="description">
      Описание
    </nz-form-label>

    <nz-form-control>
      <nz-input-group>
        <textarea
          nz-input
          name="description"
          placeholder="Описание"
          size="4"
          [(ngModel)]="description">
        </textarea>
      </nz-input-group>
    </nz-form-control>

  </nz-form-item>

  <nz-form-item>

    <nz-form-label
      nzFor="managers">
      Менеджеры
    </nz-form-label>

    <nz-form-control>
      <nz-input-group>
        <nz-select
          nzShowSearch
          nzAllowClear
          nzMode="multiple"
          name="managers"
          [disabled]="!filters.loadManagers"
          nzPlaceHolder="Менеджеры"
          [(ngModel)]="managers">

          <nz-option
            *ngFor="let manager of managersList"
            [nzLabel]="manager.firstName + '' + manager.lastName"
            [nzValue]="manager.id">
          </nz-option>

        </nz-select>
      </nz-input-group>
    </nz-form-control>

  </nz-form-item>

  <nz-form-item class="m-b-0">
    <nz-form-control>
      <button
        nz-button
        nzBlock
        [nzType]="'primary'"
        (click)="createDatabaseForm.form.markAllAsTouched()">
        Добавить базу
      </button>
    </nz-form-control>
  </nz-form-item>

</form>
