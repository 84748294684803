import {NgModule} from '@angular/core';
import {UserRestService} from "./user-rest.service";

@NgModule({
  providers: [
    UserRestService,
  ]
})
export class UserRestModule {
}
