import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserLoginDto} from './dto/user-login.dto';
import {Observable} from 'rxjs';
import {UserSignupDto} from "./dto/user-signup.dto";
import {SendCodeDto} from "../user/dto/sendCode.dto";
import {RecoverPasswordDto} from "../user/dto/recoveryPassword.dto";

@Injectable()
export class AuthRestService {
  constructor(private http: HttpClient) {
  }

  signIn(data: UserLoginDto) {
    return this.http.post<{ jwt: string, user: any }>('/auth/local', data);
  }

  signUp(data: UserSignupDto | any): Observable<any> {
    return this.http.post('/auth/local/register', data);
  }

  sendVerifyCode(data: SendCodeDto): Observable<any> {
    return this.http.post('/auth/send-email-confirmation', data);
  }
}

