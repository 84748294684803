import {getOneMapAttributesToObj} from "../../../common/services/mapAttributes/mapAttributes";
import {HistoryActionEnum} from "../../../common/enums/historyAction.enum";

export class LeadHistoryDto {
  id: number;
  lead: {
    data: {
      id: number,
      firstName: string,
      lastName: string,
      email: string,
    }
  }
  manager: {
    data: {
      id: number,
      firstName: string,
      lastName: string,
      email: string,
    }
  }
  text: string;
  createdAt: string;
  action: HistoryActionEnum;
  leadStr: string | undefined;

  constructor(data: any) {
    this.id = data.id;
    this.text = data.text;
    this.createdAt = data.createdAt;
    this.leadStr = data.leadStr;
    this.action = data.action;
    this.lead = getOneMapAttributesToObj(data.lead);
    this.manager = getOneMapAttributesToObj(data.manager);
  }
}
