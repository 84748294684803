<div
  class="layout common-layout is-default"
  [ngClass]="{
    'is-folded': isFolded,
    'is-expand': isExpand
  }">

  <div class="header">
    <div class="logo logo-dark">
      <a routerLink="/">
        <img src="assets/img/logo.jpg" alt="">
        <img class="logo-fold" src="assets/img/logo-fold.jpg" alt="Logo">
      </a>
    </div>
    <div class="nav-wrap">

      <ul class="nav-left">
        <li class="desktop-toggle">
          <a (click)="toggleFold()">
            <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
          </a>
        </li>
        <li class="mobile-toggle">
          <a (click)="toggleExpand()">
            <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
          </a>
        </li>
      </ul>

      <ul class="nav-right">

        <li>
          <span
            *ngIf="user"
            class="p-h-10 pointer"
            nz-dropdown
            [nzDropdownMenu]="profile"
            [nzTrigger]="'click'"
            [nzPlacement]="'bottomRight'">
            <nz-avatar
              *ngIf="user.firstName && user.lastName"
              class="header__avatar"
              style="background-color:#87d068;"
              [nzText]="user.firstName[0] + user.lastName[0]">
            </nz-avatar>
          </span>

          <nz-dropdown-menu #profile="nzDropdownMenu">
            <ul nz-menu class="p-b-15 p-t-20">

              <li *ngIf="user" class="p-h-20 p-b-15 m-b-10 border-bottom">
                <div>

                  <p class="m-b-0 text-dark font-weight-semibold">
                    <span *ngIf="user.firstName">{{user.firstName}} </span>
                    <span *ngIf="user.lastName"> {{user.lastName}}</span>
                  </p>
                  <p *ngIf="user.email" class="m-b-0 opacity-07">
                    {{user.email}}
                  </p>

                </div>
              </li>

              <li nz-menu-item>

                <a
                  (click)="logOut()"
                  class="p-v-5 d-flex align-items-center justify-content-between w-100">

                  <div>
                    <i
                      class="opacity-04 font-size-16"
                      nz-icon
                      nzType="lock"
                      theme="outline"></i>

                    <span class="m-l-10">Выйти</span>
                  </div>

                  <i
                    class="font-size-10"
                    nz-icon
                    nzType="right"
                    theme="outline">
                  </i>

                </a>
              </li>

            </ul>
          </nz-dropdown-menu>

        </li>
      </ul>

    </div>
  </div>

  <div
    class="side-nav"
    [ngClass]="isFolded ? 'is-folded' : ''">

    <ul
      *ngIf="isFolded !== undefined"
      nz-menu
      class="ant-menu ant-menu-root ant-menu-inline side-nav-menu"
      [ngClass]="isFolded ? 'ant-menu-inline-collapsed' : ''">

      <li
        nz-menu-item
        routerLink="/leads"

        nz-tooltip
        nzTooltipTitle="Лиды"

        (mousedown)="openLinkFromTab($event, 'leads')"

        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="team"></span>
        <span>Лиды</span>
      </li>

      <li
        *ngIf="role === UserRoleEnum.ADMIN && false"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Загрузка CSV"

        (mousedown)="openLinkFromTab($event, 'import-csv')"

        routerLink="/import-csv"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="file-word" nzTheme="outline"></span>
        <span>Загрузка CSV</span>
      </li>

      <li
        *ngIf="role === UserRoleEnum.ADMIN"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="История изменений"

        (mousedown)="openLinkFromTab($event, 'history')"

        routerLink="/history"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="history"></span>
        <span>История</span>
      </li>

      <li
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Статистика"

        (mousedown)="openLinkFromTab($event, 'statistics')"

        routerLink="/statistics"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="bar-chart"></span>
        <span>Статистика</span>
      </li>

      <li
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Статистика (Интеграции)"

        (mousedown)="openLinkFromTab($event, 'statistics-integration')"

        routerLink="/statistics-integration"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="bar-chart"></span>
        <span>Статистика (Интеграции)</span>
      </li>

      <li
        *ngIf="false"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Статистика | Депозиты"

        (mousedown)="openLinkFromTab($event, 'statistics-deposits')"

        routerLink="/statistics-deposits"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="pie-chart" nzTheme="outline"></span>
        <span>Статистика | Депозиты</span>
      </li>

      <li
        *ngIf="role === UserRoleEnum.ADMIN"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Интеграции"

        (mousedown)="openLinkFromTab($event, 'integrations')"

        routerLink="/integrations"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="schedule"></span>
        <span>Интеграции</span>
      </li>

      <li
        *ngIf="role === UserRoleEnum.ADMIN"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Базы"

        (mousedown)="openLinkFromTab($event, 'databases')"

        routerLink="/databases"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="profile"></span>
        <span>Базы</span>
      </li>

      <li
        *ngIf="role === UserRoleEnum.ADMIN"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Потоки"

        (mousedown)="openLinkFromTab($event, 'companies')"

        routerLink="/companies"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="database"></span>
        <span>Потоки</span>
      </li>

      <li
        *ngIf="role === UserRoleEnum.ADMIN"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Менеджеры"

        (mousedown)="openLinkFromTab($event, 'managers')"

        routerLink="/managers"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="user"></span>
        <span>Менеджеры</span>
      </li>

      <li
        *ngIf="false"
        nz-menu-item

        nz-tooltip
        nzTooltipPlacement="top"
        nzTooltipTitle="Чёрный список"

        (mousedown)="openLinkFromTab($event, 'black-list')"

        routerLink="/black-list"
        routerLinkActive="ant-menu-item-selected">
        <span nz-icon nzType="stop"></span>
        <span>Чёрный список</span>
      </li>

    </ul>

  </div>

  <div class="page-container">
    <div class="main-content">
      <main class="content">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>

</div>
