import {NG_VALIDATORS, Validator, AbstractControl} from "@angular/forms";
import {Directive, Input} from "@angular/core";

@Directive({
  selector: '[appConfirmEqualValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ConfirmEqualValidatorDirective,
    multi: true,
  }]
})
export class ConfirmEqualValidatorDirective implements Validator {
  @Input() appConfirmEqualValidator: string | undefined;

  // @ts-ignore
  validate(control: AbstractControl): { [key: string]: any } | null {
    // @ts-ignore
    const controlToCompare = control.parent.get(this.appConfirmEqualValidator);

    if (controlToCompare && controlToCompare.value !== control.value) {
      return {'NotEqual': true}
    }

    return null;
  }
}
