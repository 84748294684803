import {Component, Input} from '@angular/core';
import {LeadStatusEnum} from "../../pages/leads/lead-status.enum";

@Component({
  selector: 'app-timeline-block',
  templateUrl: './timeline-block.component.html',
  styleUrls: ['./timeline-block.component.scss']
})
export class TimelineBlockComponent {
  @Input() title?: string;
  @Input() data?: any;
  @Input() type?: 'status' | 'integration' = 'status';

  LeadStatusEnum = LeadStatusEnum;
}
