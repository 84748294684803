export class FullManagerDto {
  id: number;
  username: string | undefined;
  firstName: string;
  lastName: string;
  fullName: string;

  firstNameBRI: string | undefined;
  lastNameBRI: string | undefined;

  email: string;
  phone: string | undefined;

  wasOnline: Date | null;

  role: any;
  teamLead: any;
  superTeamLeads: any;

  constructor(data: FullManagerDto) {
    this.id = data?.id || -1;
    this.username = data?.username;
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.fullName = data?.firstName && data?.lastName ? data?.firstName + ' ' + data?.lastName :
      data?.firstName && !data?.lastName ? data?.firstName :
        !data?.firstName && data?.lastName ? data?.lastName :
          '—';
    this.email = data.email || '';
    this.phone = data?.phone;
    this.role = data?.role;

    this.wasOnline = data?.wasOnline || null;

    this.firstNameBRI = data?.firstNameBRI;
    this.lastNameBRI = data?.lastNameBRI;

    this.teamLead = data?.teamLead;
    this.superTeamLeads = data?.superTeamLeads;
  }
}
