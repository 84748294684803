export class PaginationDto {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;

  constructor(data?: Partial<PaginationDto>) {
    this.page = data?.page || 1;
    this.pageCount = data?.pageCount || 1;
    this.pageSize = data?.pageSize || 15;
    this.total = data?.total || 0;
  }
}
