import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: BehaviorSubject<string> = new BehaviorSubject('RU');

  constructor() {
    this.language.next(this.getLanguage());
  }

  setLanguage(language: string): void {
    this.language.next(language);
    localStorage.setItem('language', language);
  }

  getLanguage(): string {
    return localStorage.getItem('language') || 'RU';
  }
}
