import {Component, OnInit} from '@angular/core';
import {FullUserDto} from "../../rest/user/dto/fullUser.dto";
import {UserRoleEnum} from "../../common/enums/userRole.enum";
import {DatabaseFullDto} from "../../rest/databases/dto/DatabaseFull.dto";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {DatabasesRestService} from "../../rest/databases/databases-rest.service";
import {MessagesService} from "../../common/services/message-service/messages.service";
import {HistoryRestService} from "../../rest/history/history-rest.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthService} from "../../common/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NzTableQueryParams} from "ng-zorro-antd/table";
import {CreationDatabaseComponent} from "./creation/creation-database.component";
import {UpdateDatabaseComponent} from "./update/update-database.component";

@Component({
  selector: 'app-databases',
  templateUrl: './databases.component.html',
  styleUrls: ['./databases.component.scss']
})
export class DatabasesComponent implements OnInit {
  manager: FullUserDto | undefined;
  role: UserRoleEnum | undefined;
  UserRoleEnum = UserRoleEnum;

  databases: DatabaseFullDto[] = [];

  //filters
  filter = {
    search: undefined,
  }

  paginationOptions: PaginationDto = new PaginationDto({pageSize: 15});
  isLoading = true;

  document = document;
  paramsObject: any;

  constructor(
    private databasesRestService: DatabasesRestService,
    private messagesService: MessagesService,
    private historyRestService: HistoryRestService,
    private modal: NzModalService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getAttributes();
    this.getMe();
  }

  // get and set attributes
  getAttributes(): void {
    this.paramsObject = this.route.snapshot.queryParams;

    if (this.paramsObject.page) {
      this.paginationOptions.page = +this.paramsObject.page;
    }

    if (this.paramsObject.pageSize) {
      this.paginationOptions.pageSize = +this.paramsObject.pageSize;
    }

    if (this.paramsObject.search) {
      this.filter.search = this.paramsObject.search;
    }

    this.getDatabases();
  }

  setAttributes() {
    this.router.navigate(['databases'], {
        queryParams: {
          pageSize: this.paginationOptions.pageSize ? this.paginationOptions.pageSize : 15,
          page: this.paginationOptions.page ? this.paginationOptions.page : 1,
          search: this.filter.search ? this.filter.search : null,
        }
      }
    );
  }

  // search
  searchFilter(event?: any): void {

    if (event) {
      this.filter.search = event;
    }

    this.paginationOptions.page = 1;
    this.getDatabases();
  }

  clearSearch(): void {
    this.filter.search = undefined;

    this.searchFilter();
  }

  getMe(): void {
    this.authService.managerData.subscribe(
      user => {
        if (user) {
          this.manager = new FullUserDto(user);
        }
      }
    );

    this.authService.userRole.subscribe(
      role => {
        if (role) {
          this.role = role;
        }
      }
    )
  }

  // get databases
  getDatabases(): void {
    this.isLoading = true;
    this.setAttributes();

    this.databasesRestService.getDatabases(this.paginationOptions).subscribe(
      response => {
        this.databases = response.data;
        this.databases.map((database: any) => {
          if (database.managers && database.managers.data) {
            database.managers = database.managers.data
              .map((manager: any) => {
                return new FullUserDto({
                  id: manager.id,
                  firstName: manager.attributes.firstName,
                  lastName: manager.attributes.lastName,
                  email: manager.attributes.email,
                });
              });
          }
        });

        this.paginationOptions = new PaginationDto(response.meta.pagination);
        this.isLoading = false;
      }
    )
  }

  // table
  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort, filter} = params;
    this.paginationOptions.page = pageIndex;
    this.paginationOptions.pageSize = pageSize;

    this.isLoading = true;
    this.getDatabases();

    document.body.scrollTop = 0;
  }

  // create database
  createDatabase() {
    const modal = this.modal.create({
      nzTitle: 'Добавить базу',
      nzContent: CreationDatabaseComponent,
      nzFooter: null,
    })

    modal.afterClose
      .subscribe(() => {
        this.getDatabases();
      })
  }


  // update database
  updateDatabase(database: DatabaseFullDto) {
    const modal = this.modal.create({
      nzTitle: 'Редактировать поток',
      nzContent: UpdateDatabaseComponent,
      nzComponentParams: {
        databaseId: database.id,
      },
      nzFooter: null,
    })

    modal.afterClose
      .subscribe(() => {
        this.getDatabases();
      })
  }

  // delete database
  showDeleteConfirm(database ?: DatabaseFullDto) {
    this.modal.confirm({
      nzTitle: 'Вы уверены, что хотите удалить базу?',
      nzOkText: 'Удалить',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.deleteDatabase(database),
      nzCancelText: 'Отменить',
    });
  }

  deleteDatabase(database ?: DatabaseFullDto) {
    if (database && database.id) {
      this.databasesRestService.deleteDatabase(database.id).subscribe(
        _ => {
          this.messagesService.createMessage(
            'success',
            'База успешно удалена.'
          );

          this.getDatabases();
        }
      )
    }
  }
}
