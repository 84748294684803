<h3
  *ngIf="title"
  class="d-flex align-items-center flex-wrap justify-content-between m-b-20">
  {{title}}
</h3>

<nz-empty *ngIf="!data.length"></nz-empty>

<nz-timeline *ngIf="data.length && true">
  <nz-timeline-item *ngFor="let item of data">
    Дата: <span class="font-size-14 font-weight-semibold m-r-5">{{item.createdAt | date : 'dd/MM/yyyy HH:mm'}}</span>

    <ng-container *ngIf="type === 'status'">
      <span class="font-size-14">
        <br>
        Статус:
      </span>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.NEW"
        [nzColor]="'#0000ff'">
        New
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.CALL_TO_CLOSE"
        [nzColor]="'volcano'">
        Call to Close
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.LOW_POTENTIAL"
        [nzColor]="'#FFA500'">
        Low Potential
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.HIGH_POTENTIAL"
        [nzColor]="'#0f402e'">
        High Potential
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.REGISTERED"
        [nzColor]="'red'">
        Registered
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.RECOVERY"
        [nzColor]="'green'">
        Recovery
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.NO_POTENTIAL"
        [nzColor]="'red'">
        No Potential
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.IN_PRACTICE"
        [nzColor]="'orange'">
        In Practice
      </nz-tag>

      <nz-tag
        *ngIf="item.status && (item.status === LeadStatusEnum.ACTIVE || item.status === LeadStatusEnum.ACTIVE_RET)"
        [nzColor]="'green'">
        Active
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.DEPOSIT"
        [nzColor]="'#9b139b'">
        Deposit
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.SPEAKING"
        [nzColor]="'#2db7f5'">
        Взяли трубку
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.CUSTOM_10"
        [nzColor]="'red'">
        Сброс
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.CUSTOM_9"
        [nzColor]="'red'">
        Trash
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.PART_FTD"
        [nzColor]="'orange'">
        Part FTD
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.HOLD"
        [nzColor]="'geekblue'">
        Hold
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.WRONG_INFO"
        [nzColor]="'#444'">
        Wrong Info
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.WRONG_NUMBER"
        [nzColor]="'#444'">
        Wrong Number
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.INVALID_COUNTRY"
        [nzColor]="'#444'">
        Invalid Country
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.INVALID_LANGUAGE"
        [nzColor]="'#444'">
        Invalid Language
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.UNDER_18"
        [nzColor]="'#444'">
        Under 18
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.INVALID"
        [nzColor]="'#444'">
        Invalid
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.NO_ANSWER"
        [nzColor]="'#FFDB58'">
        No Answer
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.NEWER_ANSWER"
        [nzColor]="'#0f8c0b'">
        Newer Answer
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.NO_INTEREST"
        [nzColor]="'red'">
        No Interest
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.OTHER"
        [nzColor]="'#a6a5a5'">
        Other
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.CALL_BACK"
        [nzColor]="'green'">
        Call Back
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.DUPLICATE"
        [nzColor]="'#444'">
        Duplicate
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.DUPLICATE_INTEGRATION"
        [nzColor]="'#444'">
        Duplicate Integration
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.IN_WORK"
        [nzColor]="'green'">
        In Work
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.DO_NOT_CALL"
        [nzColor]="'red'">
        Do Not Call
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.ERROR"
        [nzColor]="'red'">
        Error
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.BLACK_LIST"
        [nzColor]="'#444'">
        Black List
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.IN_PROGRESS"
        [nzColor]="'cyan'">
        In Progress
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.WELCOME_CALL"
        [nzColor]="'cyan'">
        Welcome Call
      </nz-tag>

      <nz-tag
        *ngIf="item.status && item.status === LeadStatusEnum.SMALL_DEPOSIT"
        [nzColor]="'volcano'">
        Small Deposit
      </nz-tag>
    </ng-container>

    <ng-container *ngIf="type === 'integration'">
      <p class="font-size-14 m-b-5">
        Статус:
        <nz-tag [nzColor]="'green'">{{item.status}}</nz-tag>
      </p>

      <p
        *ngIf="item.integration && item.integration.name"
        class="font-size-14 m-b-5">
        Интеграция: <span class="font-weight-semibold">{{item.integration.name}}</span>
      </p>

      <div class="timeline__scroll">
        <pre><code>{{item.message}}</code></pre>
      </div>

    </ng-container>

  </nz-timeline-item>
</nz-timeline>
