import {NgModule} from '@angular/core';
import {AppSettings} from "./settings.service";
import {LocalStorageService} from "./LocalStorage.service";
import {DatePipe} from "@angular/common";

@NgModule({
  providers: [
    AppSettings,
    LocalStorageService,
    DatePipe
  ]
})
export class SettingsModule {
}
