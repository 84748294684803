import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {HistoryActionEnum} from "../../common/enums/historyAction.enum";
import {LeadHistoryDto} from "./dto/leadHistoryDto";
import {LeadHistoryCreateDto} from "./dto/leadHistoryCreate.dto";

@Injectable({
  providedIn: 'root'
})
export class HistoryRestService {
  constructor(
    private http: HttpClient,
  ) {}

  getLeadHistory(options: {
    createdAt?: Date[],
    pagination?: PaginationDto,
    sort?: string,
    managers?: number[],
    lead?: number,
    actions?: HistoryActionEnum[],
  }): Observable<any> {
    const query: any = {
      populate: ['manager', 'lead'],
    };

    query['sort'] = 'id:DESC';

    query.filters = {};

    if (options.pagination) {
      query['pagination'] = options.pagination;
    }

    if (options?.createdAt) {
      query.createdAt = options.createdAt;
    }

    if (options.sort) {
      query.sort = options.sort;
    }

    if (options.managers) {
      query.filters.manager = {
        id: {
          $in: options.managers
        },
      }
    }

    if (options.lead) {
      query.filters.lead = {
        id: options.lead,
      }
    }

    if (options.actions) {
      query.filters.action = {
        $in: options.actions
      }
    }

    return this.http.get(`/histories?${qs.stringify(query)}`).pipe(
      map((response: any) => {
        response.data = getAllMapAttributesToObj(response).data.map((history: any) => new LeadHistoryDto(history));
        return response;
      })
    );
  }

  createLeadHistory(data: LeadHistoryCreateDto) {
    return this.http.post('/histories', {data});
  }
}
