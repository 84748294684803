export class FilterDto {
  id: number;
  user: any;
  data: any;

  constructor(data: FilterDto) {
    this.id = data.id;
    this.data = data.data;
    this.user = data.user;
  }
}
