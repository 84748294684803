import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DatabaseCreateDto} from "./dto/DatabaseCreate.dto";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {
  getAllMapAttributesToObj,
  getAllMapAttributesToObjResponseData,
  getOneMapAttributesToObj
} from "../../common/services/mapAttributes/mapAttributes";
import {PaginationDto} from "../../common/pagination/pagination.dto";

@Injectable({
  providedIn: 'root'
})
export class DatabasesRestService {
  constructor(
    private http: HttpClient
  ) {
  }

  getDatabase(id: number): Observable<any> {
    return this.http.get(`/databases/${id}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  getDatabases(pagination?: PaginationDto, search?: string): Observable<any> {
    const query: any = {};

    query['filters'] = {};

    query['sort'] = 'id:DESC';

    query['populate'] = {
      managers: true,
    };

    if (pagination) {
      query['pagination'] = pagination;
    }

    if (search) {
      query['filters'].name = {
        $containsi: search,
      };
    }

    return this.http.get(`/databases?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  deleteDatabase(id: number): Observable<any> {
    return this.http.delete(`/databases/${id}`);
  }

  createDatabase(data: DatabaseCreateDto): Observable<any> {
    return this.http.post('/databases', {data});
  }

  updateDatabase(id: number, data: any) {
    return this.http.put(`/databases/${id}`, {data: data});
  }
}
