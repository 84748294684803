import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";

export enum LeadStatusEnum {
  NEW = 'NEW',
  CALL_TO_CLOSE = 'CALL_TO_CLOSE',
  HIGH_POTENTIAL = 'HIGH_POTENTIAL',
  LOW_POTENTIAL = 'LOW_POTENTIAL',
  REGISTERED = 'REGISTERED',
  RECOVERY = 'RECOVERY',
  NO_POTENTIAL = 'NO_POTENTIAL',
  IN_PRACTICE = 'IN_PRACTICE',
  ACTIVE = 'ACTIVE',
  DEPOSIT = 'DEPOSIT',
  SPEAKING = 'SPEAKING',
  PART_FTD = 'PART_FTD',
  HOLD = 'HOLD',
  WRONG_INFO = 'WRONG_INFO',
  WRONG_NUMBER = 'WRONG_NUMBER',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
  INVALID_LANGUAGE = 'INVALID_LANGUAGE',
  INVALID = 'INVALID',
  UNDER_18 = 'UNDER_18',
  NO_ANSWER = 'NO_ANSWER',
  NO_INTEREST = 'NO_INTEREST',
  OTHER = 'OTHER',
  NO_ANSWER_1_5 = 'NO_ANSWER_1_5',
  NEWER_ANSWER = 'NEWER_ANSWER',
  CALL_BACK = 'CALL_BACK',
  DUPLICATE = 'DUPLICATE',
  IN_WORK = 'IN_WORK',
  DO_NOT_CALL = 'DO_NOT_CALL',
  DUPLICATE_INTEGRATION = 'DUPLICATE_INTEGRATION',
  ERROR = 'ERROR',
  BLACK_LIST = 'BLACK_LIST',
  IN_PROGRESS = 'IN_PROGRESS',
  CUSTOM_1 = 'CUSTOM_1',
  CUSTOM_2 = 'CUSTOM_2',
  CUSTOM_3 = 'CUSTOM_3',
  CUSTOM_4 = 'CUSTOM_4',
  CUSTOM_5 = 'CUSTOM_5',
  CUSTOM_6 = 'CUSTOM_6',
  CUSTOM_7 = 'CUSTOM_7',
  CUSTOM_8 = 'CUSTOM_8',
  CUSTOM_9 = 'CUSTOM_9',
  CUSTOM_10 = 'CUSTOM_10',
  ACTIVE_RET = 'ACTIVE_RET',
  WELCOME_CALL = 'WELCOME_CALL',
  SMALL_DEPOSIT = 'SMALL_DEPOSIT',
}

export const leadStatusList: NzTableFilterList = [
  {
    text: 'New',
    value: LeadStatusEnum.NEW,
  },
  {
    text: 'No Potential',
    value: LeadStatusEnum.NO_POTENTIAL,
  },
  {
    text: 'No Answer',
    value: LeadStatusEnum.NO_ANSWER,
  },
  // {
  //   text: 'No Answer 1-5',
  //   value: LeadStatusEnum.NO_ANSWER_1_5,
  // },
  {
    text: 'Newer Answer',
    value: LeadStatusEnum.NEWER_ANSWER,
  },
  {
    text: 'Invalid',
    value: LeadStatusEnum.INVALID,
  },
  {
    text: 'Part FTD',
    value: LeadStatusEnum.PART_FTD,
  },
  {
    text: 'Duplicate',
    value: LeadStatusEnum.DUPLICATE,
  },
  {
    text: 'Duplicate Integration',
    value: LeadStatusEnum.DUPLICATE_INTEGRATION,
  },
  {
    text: 'Wrong Info',
    value: LeadStatusEnum.WRONG_INFO,
  },
  {
    text: 'Wrong Number',
    value: LeadStatusEnum.WRONG_NUMBER,
  },
  {
    text: 'Invalid Country',
    value: LeadStatusEnum.INVALID_COUNTRY,
  },
  {
    text: 'Invalid Language',
    value: LeadStatusEnum.INVALID_LANGUAGE,
  },
  {
    text: 'Under 18',
    value: LeadStatusEnum.UNDER_18,
  },
  {
    text: 'In Practice',
    value: LeadStatusEnum.IN_PRACTICE,
  },
  {
    text: 'Registered',
    value: LeadStatusEnum.REGISTERED,
  },
  {
    text: 'Deposit',
    value: LeadStatusEnum.DEPOSIT,
  },
  {
    text: 'Call to Close',
    value: LeadStatusEnum.CALL_TO_CLOSE,
  },
  {
    text: 'Recovery',
    value: LeadStatusEnum.RECOVERY,
  },
  {
    text: 'Call Back',
    value: LeadStatusEnum.CALL_BACK,
  },
  {
    text: 'Other',
    value: LeadStatusEnum.OTHER,
  },
  {
    text: 'Hold',
    value: LeadStatusEnum.HOLD,
  },
  {
    text: 'No interest',
    value: LeadStatusEnum.NO_INTEREST,
  },
  {
    text: 'Active',
    value: LeadStatusEnum.ACTIVE,
  },
  {
    text: 'Взяли трубку',
    value: LeadStatusEnum.SPEAKING,
  },
  {
    text: 'Сброс',
    value: LeadStatusEnum.CUSTOM_10,
  },
  {
    text: 'Trash',
    value: LeadStatusEnum.CUSTOM_9,
  },
  {
    text: 'High Potential',
    value: LeadStatusEnum.HIGH_POTENTIAL,
  },
  {
    text: 'Low Potential',
    value: LeadStatusEnum.LOW_POTENTIAL,
  },
  {
    text: 'In Work',
    value: LeadStatusEnum.IN_WORK,
  },
  {
    text: 'Do not Call',
    value: LeadStatusEnum.DO_NOT_CALL,
  },
  {
    text: 'Error',
    value: LeadStatusEnum.ERROR,
  },
  {
    text: 'Black List',
    value: LeadStatusEnum.BLACK_LIST,
  },
  {
    text: 'In Progress',
    value: LeadStatusEnum.IN_PROGRESS,
  },
  {
    text: 'Active Ret',
    value: LeadStatusEnum.ACTIVE_RET,
  },
  {
    text: 'Welcome Call',
    value: LeadStatusEnum.WELCOME_CALL,
  },
  {
    text: 'Small Deposit',
    value: LeadStatusEnum.SMALL_DEPOSIT,
  },
];

