export class DatabaseCreateDto {
  name: string;
  description?: string;
  managers: number[];

  constructor(data: DatabaseCreateDto) {
    this.name = data?.name;
    this.description = data?.description;
    this.managers = data?.managers;
  }
}
