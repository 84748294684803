<button
  nz-button
  nzBlock
  nzType="primary"
  (click)="createFilter()"
  class="m-b-10">
  Добавить фильтр
</button>

<ul
  *ngIf="helpers.initList"
  nz-list
  [nzDataSource]="filtersList"
  [nzLoading]="helpers.loadList"
  class="font-size-14 filters"
  nzSize="small">

  <li nz-list-item
      *ngFor="let filter of filtersList"
      class="d-flex flex-column align-items-stretch">

    <div class="d-flex align-items-center justify-content-between w-100">

      <p class="font-size-15 m-b-0 p-r-5 font-weight-semibold text-dashed text-wrap flex-shrink-1">
        {{ filter.data.name }}
      </p>

      <a
        *ngIf="filter"
        nz-dropdown
        [nzDropdownMenu]="menu"
        class="d-inline hover-button flex-shrink-0">
        <i
          nz-icon
          nzType="appstore"
          theme="outline">
        </i>
      </a>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>

          <li
            (click)="editFilter(filter)"
            nz-menu-item>

            <i
              nz-icon
              nzType="edit"
              class="m-r-5">
            </i>

            Редактировать

          </li>

          <li
            (click)="showDeleteConfirm(filter)"
            nz-menu-item>

            <i
              nz-icon
              nzType="stop"
              class="m-r-5">
            </i>

            Удалить

          </li>

        </ul>
      </nz-dropdown-menu>

    </div>

    <div class="filters__list">

      <div
        *ngIf="filter.data.filters.createdAtDate"
        class="filters__manager m-b-5">

        <span class="font-weight-semibold">
          Дата добавления:
        </span>

        {{checkDate(filter.data.filters.createdAtDate)}}
      </div>

      <div
        *ngIf="filter.data.filters.firstDepositeTime"
        class="filters__manager m-b-5">

        <span class="font-weight-semibold">
          Дата депозита:
        </span>

        {{checkDate(filter.data.filters.firstDepositeTime)}}
      </div>

      <div
        *ngIf="filter.data.filters.companies && filter.data.filters.companies.length"
        class="filters__manager m-b-5">

        <span class="font-weight-semibold">
          Потоки:
        </span>

        <ng-container *ngFor="let company of filter.data.filters.companies">
          <nz-tag
            [nzColor]="'geekblue'"
            class="font-size-11 m-b-5">
            {{checkCompanies(company)}}
          </nz-tag>
        </ng-container>
      </div>

      <div
        *ngIf="filter.data.filters.databases && filter.data.filters.databases.length"
        class="filters__manager m-b-5">

        <span class="font-weight-semibold">
          База:
        </span>

        <ng-container *ngFor="let database of filter.data.filters.databases">
          <nz-tag
            [nzColor]="'geekblue'"
            class="font-size-11 m-b-5">
            {{checkDatabases(database)}}
          </nz-tag>
        </ng-container>
      </div>

      <div
        *ngIf="filter.data.filters.status && filter.data.filters.status.length"
        class="filters__manager">

        <span class="font-weight-semibold">
          Статусы:
        </span>

        <ng-container *ngFor="let status of filter.data.filters.status">
          <nz-tag
            [nzColor]="'geekblue'"
            class="font-size-11 m-b-5">
            {{checkStatus(status, filter.data.type)}}
          </nz-tag>
        </ng-container>
      </div>

      <div
        *ngIf="filter.data.filters.country"
        class="filters__manager m-b-5">

        <span class="font-weight-semibold">
          Страна:
        </span>

        {{filter.data.filters.country}}
      </div>

    </div>

  </li>

  <ng-template #pagination>
    <nz-pagination
      [(nzPageIndex)]="paginationOptions.page"
      [(nzPageSize)]="paginationOptions.pageSize"
      [nzTotal]="paginationOptions.total"
      (nzPageIndexChange)="getFilters()">
    </nz-pagination>
  </ng-template>
</ul>

<nz-skeleton *ngIf="!helpers.initList"></nz-skeleton>
