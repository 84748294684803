import {Injectable} from '@angular/core';
import {FullManagerDto} from "../../../rest/auth/dto/full-manager.dto";
import {UserRoleEnum} from "../../enums/userRole.enum";

export interface User {
  id: number;
  firstName: string | undefined;
  lastName: string | undefined;
  fullName: string;
  email: string | undefined;
  phone: string | undefined;
  role?: UserRoleEnum;
  teamLead: User | null;
  managers: User[];
  managersIds: number[];
}

@Injectable({
  providedIn: 'root'
})
export class ManagersService {

  constructor() {
  }

  mapUsers(inputUsers: FullManagerDto[]): User[] {
    const managers: FullManagerDto[] = [];
    const otherManager: User[] = [];

    const noTeamLead: User = {
      id: -1,
      firstName: 'No',
      lastName: 'Team Lead',
      fullName: 'No Team Lead',
      email: 'noteam_lead@gmail.com',
      phone: '12345',
      managers: [],
      teamLead: null,
      managersIds: [],
    }

    inputUsers.forEach((manager: FullManagerDto) => {
      if (manager.role && manager.role.name === 'Manager') {
        managers.push(manager);
      } else {
        otherManager.push({
          id: manager.id,
          firstName: manager.firstName,
          lastName: manager.lastName,
          fullName: manager.fullName,
          email: manager.email,
          phone: manager.phone,
          role: manager.role,
          teamLead: manager.teamLead,
          managers: [],
          managersIds: [],
        });
      }
    });

    managers.forEach((manager: FullManagerDto) => {
      const teamLead = manager.teamLead ? manager.teamLead : null;
      const teamToUpdate = teamLead ? otherManager.find(manager => manager.id === teamLead.id) : false;

      const managerObject = {
        id: manager.id,
        firstName: manager.firstName,
        lastName: manager.lastName,
        fullName: manager.fullName,
        email: manager.email,
        phone: manager.phone,
        role: manager.role,
        teamLead: manager.teamLead,
        managers: [],
        managersIds: [manager.id],
      };

      if(teamLead && teamToUpdate) {
        teamToUpdate.managers.push(managerObject);
        teamToUpdate.managersIds.push(managerObject.id);
      } else {
        noTeamLead.managers.push(managerObject);
        noTeamLead.managersIds.push(managerObject.id);
      }
    });

    if(noTeamLead.managers.length) otherManager.push(noTeamLead);

    return otherManager;
  }
}
