import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {FilterDto} from "./dto/filter.dto";

@Injectable({
  providedIn: 'root'
})
export class FiltersRestService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getFilters(options: {
    pagination?: PaginationDto,
    type?: 'LEAD' | 'USER',
    user: number,
  }): Observable<any> {

    const query: any = {
      populate: ['user'],
    };

    query['sort'] = 'id:DESC';

    query.filters = {};

    if (options.pagination) {
      query['pagination'] = options.pagination;
    }

    if (options.user) {
      query.filters.user = {
        id: options.user,
      }
    }

    return this.http.get(`/filters?${qs.stringify(query)}`).pipe(
      map((response: any) => {
        response.data = getAllMapAttributesToObj(response).data
          .map((filter: FilterDto) => new FilterDto(filter))
          .filter((filter: FilterDto) => filter.data.type === options.type);
        return response;
      })
    );
  }

  getFilterByID(id: number): Observable<any> {
    return this.http.get(`/filters/${id}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  createFilter(filter: any): Observable<any> {
    return this.http.post(`/filters/`, {data: filter})
  }

  updateFilter(id: number, filter: any): Observable<any> {
    return this.http.put(`/filters/${id}`, {data: filter})
  }

  deleteFilter(id: number) {
    return this.http.delete(`/filters/${id}`);
  }
}
