import {PlatformEnum} from "../../../common/enums/platform.enum";
import {DatabaseFullDto} from "../../databases/dto/DatabaseFull.dto";

export class FullUserDto {
  id: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;

  fullName?: string;

  constructor(data?: FullUserDto) {
    this.id = data?.id || -1;
    this.username = data?.username;
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;

    this.email = data?.email;

    this.fullName = data?.firstName && data?.lastName ? data?.firstName + ' ' + data?.lastName :
      data?.firstName && !data?.lastName ? data?.firstName :
        !data?.firstName && data?.lastName ? data?.lastName :
          '—';
  }
}
