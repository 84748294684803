import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthDashboardGuard} from './common/guards/auth-dashboard.guard';
import {AuthLoginGuard} from './common/guards/auth-login.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layouts/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthDashboardGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthLoginGuard]
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
